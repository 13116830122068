import React from "react";
import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby";

export const RecommendedCovers = ({ header = "We've Got you Covered" }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wpPage(id: { eq: "cG9zdDo2MDk3Ng==" }) {
            id
            title
            layout {
              layoutType
              sections {
                photo {
                  slug
                  altText
                  title
                  localFile {
                    publicURL
                  }
                }
                emphasizedProduct
                photoPosition
                sectionTitle
                sectionTitleLink
                sectionBody
                buttonText
                buttonLink
                postButtonText
              }
            }
          }
        }
      `}
      render={({ wpPage }) => (
        <div>
          <div className="mb-8 text-grey-darker text-center">
            <h2 className="text-xl">{header}</h2>
          </div>
          <div className="grid lg:grid-cols-2 xl:grid-cols-4 lg:gap-x-4 gap-y-6 mb-12 mx-auto px-4 xl:px-0 max-w-5xl">
            {wpPage.layout.sections.map((section, index) => {
              const emphasizedBackground = section.emphasizedProduct
                ? `${process.env.THEME_COLOR_PRIMARY}-darker`
                : "grey-darkest";
              return (
                <div
                  className={`flex flex-wrap justify-center text-grey-darkest`}
                  key={index}
                >
                  <div className="relative flex flex-col rounded border shadow-md">
                    <div className="flex-1">
                      {section.emphasizedProduct && (
                        <p
                          className={`absolute top-0 -translate-y-1/2 transform rounded-full bg-${process.env.THEME_COLOR_PRIMARY} py-1.5 px-4 text-sm font-semibold text-white`}
                        >
                          Most popular
                        </p>
                      )}
                      <h3
                        className={`font-medium bg-${emphasizedBackground} text-white w-full text-center py-4`}
                      >
                        <Link
                          to={section.sectionTitleLink}
                          className="text-white no-underline"
                        >
                          {section.sectionTitle}
                        </Link>
                      </h3>
                      <div className="w-full">
                        <Link to={section.sectionTitleLink}>
                          <img
                            src={
                              section.photo && section.photo.localFile.publicURL
                            }
                            className="w-full object-cover h-36"
                            alt={section.photo && section.photo.alt}
                            title={section.photo && section.photo.title}
                          />
                        </Link>
                      </div>
                      <div className="p-4">
                        <Link
                          to={section.buttonLink}
                          className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded uppercase shadow text-center`}
                        >
                          {section.buttonText}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    />
  );
};
